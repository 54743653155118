/* Importing fonts from Google */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800;900&display=swap');

/* Reseting */

:root {
    --goldwin-gray: #a0a0a0;
    /* Approximate color match to the Goldwin logo */
}


* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Poppins', sans-serif;
}

body {
    background: #ecf0f3;
}

.wrapper {
    max-width: 350px;
    min-height: 500px;
    margin: 80px auto;
    padding: 40px 30px 30px 30px;
    background-color: #ecf0f3;
    border-radius: 15px;
    box-shadow: 13px 13px 20px #cbced1, -13px -13px 20px #fff;
}

.logo {
    width: 100px;
    margin: auto;
}

.logo img {
    width: 100%;
    height: 100px;
    object-fit: cover;
    border-radius: 50%;
    box-shadow: 0px 0px 3px #5f5f5f,
        -1px -1px 0px 5px #ecf0f3,
        8px 8px 15px #a7aaa7,
        -8px -8px 15px #fff;
}

.wrapper .name {
    font-weight: 600;
    font-size: 1.4rem;
    letter-spacing: 1.3px;
    padding-left: 10px;
    color: var(--goldwin-gray);
}

.wrapper .form-field input {
    width: 100%;
    display: block;
    border: none;
    outline: none;
    background: none;
    font-size: 1.2rem;
    color: var(--goldwin-gray);
    padding: 10px 15px 10px 10px;
    /* border: 1px solid red; */
}

.wrapper .form-field {
    padding-left: 10px;
    margin-bottom: 20px;
    border-radius: 20px;
    box-shadow: inset 8px 8px 8px #cbced1, inset -8px -8px 8px #fff;
}

.wrapper .form-field .fas {
    color: var(--goldwin-gray);
}

.wrapper .btn {
    box-shadow: none;
    width: 100%;
    height: 40px;
    background-color: #ecf0f3;
    color: var(--goldwin-gray);
    border-radius: 25px;
    box-shadow: 3px 3px 3px #b1b1b1,
        -3px -3px 3px #fff;
    letter-spacing: 1.3px;
}

.wrapper .btn:hover {
    background-color: transparent;
}

.wrapper .btn:active {
    /* background-color: transparent; */
    box-shadow: none;
}

.wrapper a {
    text-decoration: none;
    font-size: 0.8rem;
    color: var(--goldwin-gray);
}


/* CSS Checkbox  */
.checkbox-wrapper-4 * {
    box-sizing: border-box;
}

.checkbox-wrapper-4 .cbx {
    -webkit-user-select: none;
    user-select: none;
    cursor: pointer;
    padding: 6px 8px;
    border-radius: 6px;
    overflow: hidden;
    transition: all 0.2s ease;
    display: inline-block;
}

.checkbox-wrapper-4 .cbx:not(:last-child) {
    margin-right: 6px;
}

.checkbox-wrapper-4 .cbx:hover {
    background: rgba(0, 119, 255, 0.06);
}

.checkbox-wrapper-4 .cbx span {
    float: left;
    vertical-align: middle;
    transform: translate3d(0, 0, 0);
}

.checkbox-wrapper-4 .cbx span:first-child {
    position: relative;
    width: 18px;
    height: 18px;
    border-radius: 4px;
    transform: scale(1);
    border: 1px solid #cccfdb;
    transition: all 0.2s ease;
    box-shadow: 0 1px 1px rgba(0, 16, 75, 0.05);
}

.checkbox-wrapper-4 .cbx span:first-child svg {
    position: absolute;
    top: 3px;
    left: 2px;
    fill: none;
    stroke: #fff;
    stroke-width: 2;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-dasharray: 16px;
    stroke-dashoffset: 16px;
    transition: all 0.3s ease;
    transition-delay: 0.1s;
    transform: translate3d(0, 0, 0);
}

.checkbox-wrapper-4 .cbx span:first-child:before {
    content: "";
    width: 100%;
    height: 100%;
    background: #506EEC;
    display: block;
    transform: scale(0);
    opacity: 1;
    border-radius: 50%;
}

.checkbox-wrapper-4 .cbx span:last-child {
    padding-left: 8px;
    line-height: 18px;
}

.checkbox-wrapper-4 .cbx:hover span:first-child {
    border-color: #3c53c7;
}

.checkbox-wrapper-4 .inp-cbx {
    position: absolute;
    visibility: hidden;
}

.checkbox-wrapper-4 .inp-cbx:checked+.cbx span:first-child {
    background: #3c53c7;
    border-color: #3c53c7;
    animation: wave-4 0.4s ease;
}

.checkbox-wrapper-4 .inp-cbx:checked+.cbx span:first-child svg {
    stroke-dashoffset: 0;
}

.checkbox-wrapper-4 .inp-cbx:checked+.cbx span:first-child:before {
    transform: scale(3.5);
    opacity: 0;
    transition: all 0.6s ease;
}

.checkbox-wrapper-4 .inp-cbx:disabled + .cbx {
    cursor: not-allowed;
    opacity: 0.6;
  }
  
  .checkbox-wrapper-4 .inp-cbx:disabled + .cbx span:first-child {
    border-color: #cccfdb;
    background-color: #f2f2f2;
  }
  
  .checkbox-wrapper-4 .inp-cbx:disabled + .cbx:hover {
    background: none;
  }
  
  .checkbox-wrapper-4 .inp-cbx:disabled + .cbx span:last-child {
    color: #999;
  }
  
  /* Adjust the existing hover styles to not apply when disabled */
  .checkbox-wrapper-4 .inp-cbx:not(:disabled) + .cbx:hover {
    background: rgba(160, 160, 160, 0.1);
  }
  
  .checkbox-wrapper-4 .inp-cbx:not(:disabled) + .cbx:hover span:first-child {
    border-color: #8a8a8a;
  }
@keyframes wave-4 {
    50% {
        transform: scale(0.9);
    }
}

.checkbox-wrapper-4 .cbx span:last-child {
    color: var(--goldwin-gray);
    font-size: 0.9rem;
}

/* Adjust existing styles */
.wrapper .btn {
    margin-top: 10px;
}

.form-field {
    position: relative;
}

.password-toggle {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    background: none;
    border: none;
    cursor: pointer;
    color: var(--goldwin-gray);
}

.password-toggle:focus {
    outline: none;
}

@media(max-width: 380px) {
    .wrapper {
        margin: 30px 20px;
        padding: 40px 15px 15px 15px;
    }

    .checkbox-wrapper-4 .cbx span:last-child {
        font-size: 0.8rem;
    }

    .password-toggle {
        right: 5px;
    }
}